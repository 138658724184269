import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Product } from 'src/app/product.model';
import { ProductService } from 'src/app/product.service';
import { Upload } from 'src/app/upload';
import { UploadService } from 'src/app/upload.service';
import * as firebase from 'firebase/app';
import 'firebase/firestore'

const CATEGORIES = [ "Sensors & Controllers", "Devices & Equipments", "Infrastructure", "Softwares"]

const SUBCATEGORIES = [
  "Motion & vibration sensors",
  "Door & window sensors",
  "Plugs",
  "Temperature & humidity sensors",
  "Flood & Water leak sensors",
  "Switches & Dimmers",
  "Speakers",
  "Bulbs & Lights", 
  "Cleaning robots", 
  "Video Cameras", 
  "Locks & Doorbells",
  "Ships", 
  "Servers", 
  "Cloud providers", 
  "Hub & Bridges", 
  "Routers & Switches",
  "Automation", 
  "Assistant", 
  "Apps & Tools", 
  "Storage"
]

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent implements OnInit {
  newProductForm: FormGroup;
  newProduct: Product;
  controls: any; 
  categories = CATEGORIES; 
  subcategories = SUBCATEGORIES; 
  selectedFiles: FileList; 
  currentUpload: Upload;
  categoriesList: any;
  categoryList: any;
  subcategoryList: any; 
  category: string = "Devices & Equipments"; 
  isLoaded: boolean = false; 
  constructor(
    private formBuilder: FormBuilder, 
    private productService: ProductService,
    private _snackBar: MatSnackBar,
    private router: Router,
    private uploadService: UploadService

  ) { }


  ngOnInit(): void {
    this.initForm();
  }

  initForm() {

    this.newProduct = new Product();
    this.controls = {};
    this.categoryList = [];
    this.subcategoryList = [];
    this.productService.getCategories()
    .then((list) => {
      this.categoriesList = list; 
      for(let category in <Object>list) {
        this.categoryList.push(category);
        console.log(list);
        list[category].forEach(subcategory => {
          this.subcategoryList.push(subcategory);
        });
      }
      this.isLoaded = true; 

    })
    .catch((error) => {
      console.error(error);
    })

    for (let property in this.newProduct) {
      this.controls[property] = new FormControl('', Validators.required);
    }
    this.newProductForm = this.formBuilder.group({
      id: ['', Validators.required], 
      name: '',
      brand: '',
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      description: '', 
      network:'',
      officialUrl: '',
      amazonUrl: '',
      imageUrl: '',
      likes: 0
    })

  }

  openSnackBar(text: string) {
    this._snackBar.open(text, "Close", {duration: 5000});
  }

  onSubmitForm() {
    this.newProduct.parse(this.newProductForm.value);
    this.newProduct['likes']= 0;
    this.newProductForm.get('')
    this.productService.createProduct(this.newProduct)
    .then((message) => {
      this.openSnackBar(<string> message); 
      this.router.navigate(['products/' + this.newProduct.id])
    })
    .catch((error) => {
      this.openSnackBar(error);
    })
  }
  onSubmitFormAndNew() {
    this.newProduct.parse(this.newProductForm.value);
    this.productService.createProduct(this.newProduct)
    .then((message) => {
      this.openSnackBar(<string> message); 
      this.ngOnInit();
    })
    .catch((error) => {
      this.openSnackBar(error);
    })
  }

  detectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  upload() {
    let file = this.selectedFiles.item(0);
    this.currentUpload = new Upload(file); 
    this.uploadService.pushUpload(this.currentUpload);
    if(this.currentUpload?.progress == 100){
      this.openSnackBar("File uploaded");
    }
  }
}
