import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import { ProductService } from 'src/app/product.service';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  productName = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;

  constructor(
    private productService: ProductService, 
    private router: Router
    ) { }

  ngOnInit(): void {

    if(this.productService.dictOfProducts) {
      this.setOptions(this.productService.dictOfProducts);
    }

    this.productService.productObjectObservable.subscribe((dictOfProducts) => {
      this.setOptions(dictOfProducts);
    })

    this.filteredOptions = this.productName.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const re = RegExp(`.*${value.toLowerCase().split('').join('.*')}.*`)

    return this.options.filter(option => option.toLowerCase().match(re));
  }

  setOptions(dictOfProducts: any) {
    for(let product in <Object> dictOfProducts) {
      let productValue = new Object(dictOfProducts[product])
      this.options.push(productValue['brand'] + ' '+ productValue['name']);
    }
  }

  onSubmitForm() {
    const value = this.productName.value;
    let list = this.productService.dictOfProducts;
    for (let product in list) {
      let name = list[product]['brand'] + ' '+ list[product]['name']
      if (value == name ) {
        console.log("Value equals: " + value + " And name equals: " + name);
        console.log(product);
        this.router.navigate(['products/'  + product ]);
      }
    }
  }

}
