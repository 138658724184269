import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProductService } from 'src/app/product.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-leave-comment',
  templateUrl: './leave-comment.component.html',
  styleUrls: ['./leave-comment.component.css']
})
export class LeaveCommentComponent implements OnInit {
  @Input() productId:string
  comment = new FormControl('')
  show: boolean = false;
  isRatingSubmitted: boolean = false;
  ratingSubmitted: number;
  starsDisplay: Array<boolean>;
  @Output() submitted: EventEmitter<any> = new EventEmitter();

  constructor(
    private productService: ProductService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userService.initService()
      .then((result) => {
        this.starsDisplay = [false, false, false, false, false]
        this.show = <boolean> result;
      })
      .catch((error) => {
        console.error(error);
      })

  }

  onOverStar(rating: number){
    if(!this.isRatingSubmitted) {
      this.setStarsDisplay(rating);

    }
    else {
      this.setStarsDisplay(0);
      this.setStarsDisplay(rating);
    }
  }

  onLeaveStar() {
    if(!this.isRatingSubmitted) {
      this.setStarsDisplay(0);
    }
    else {
      this.setStarsDisplay(this.ratingSubmitted);
    }

  }

  onClickStar(rating: number) {
    this.isRatingSubmitted = false;
    this.onLeaveStar();
    this.onOverStar(rating);
    this.ratingSubmitted = rating;
    this.isRatingSubmitted = true;

  }

  setStarsDisplay(rating: number) {
    this.starsDisplay = [false, false, false, false, false]

    if (rating != 0) {
      this.starsDisplay = [false, false, false, false, false]
      var arr = [...Array(rating).keys()]
      for (let index of arr) {
        this.starsDisplay[index] = true;
      }    
    }
  }

  submitComment() {
    this.productService.setComment(this.productId, this.userService.uid, this.userService.pseudo, this.ratingSubmitted, this.comment.value)
    .then(() => {
      this.submitted.emit();
    })
    .catch((error) => {
      console.error(error);
    })
  
  }
}
