import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private userService: UserService
    ) { }

  createNewUser (email: string, password: string, pseudo: string) {
      return new Promise (
        (resolve, reject) => {
          firebase.default.auth().createUserWithEmailAndPassword(email, password).then(
            (userData) => {
              firebase.default.firestore().collection("users").doc(userData.user.uid).set({
                email: email
              })
              .then(() => {
                firebase.default.auth().currentUser.updateProfile({displayName: pseudo});
                firebase.default.firestore().collection("users").doc(userData.user.uid).set({pseudo: pseudo});
                firebase.default.firestore().collection("users").doc("public").update({
                  allPseudos: firebase.default.firestore.FieldValue.arrayUnion(pseudo)
                })
                this.userService.initUser(userData.user.uid)
                  .then(() => {
                    localStorage.setItem('pseudo', pseudo);
                    resolve(userData.user.uid);
                  })
                  .catch((error) => {
                    reject(error);
                  })
              })
              .catch((error) => {
                reject(error);
              })
            },
            (error) => {
              reject(error);
            }
          );
        }
      );
    }

  signInUser (email: string, password: string) {
    return new Promise (
      (resolve, reject) => {
        firebase.default.auth().signInWithEmailAndPassword(email, password).then(
          (userCreds) => {
            const now = new Date().toISOString();
            this.userService.uid = userCreds.user.uid;
            this.userService.initUser(userCreds.user.uid).then((value) => {
              resolve();
            });
          },
          (error) => {
            reject(error);
          }
        )
      }
    )
  }

  sendPasswordResetMail(email:string) {
    firebase.default.auth().sendPasswordResetEmail(email).then(function() {
    }).catch(function(error) {
  
    });
  }

  verifyOob(oob: string) {
    return new Promise (
      (resolve, reject) => { 
        firebase.default.auth().verifyPasswordResetCode(oob).then(
          (email) => {
            resolve(email);
          },
          (error) => {
            reject(error);
          }
        )
      }
    )
  }

  resetPassword(oob:string, newPassword: string){
    return new Promise (
      (resolve, reject) => {
        firebase.default.auth().confirmPasswordReset(oob, newPassword).then(
          () => {
            resolve(); 
          },
          (error) => {
            reject(error);
          }
        )
      }
    )
  }

  signOutUser() {
    return new Promise ((resolve, reject) => {
      firebase.default.auth().signOut()
      .then(() => {
        this.userService.onSignOut();
        resolve();
      })
      .catch(
        (error) => {
          reject(error); 
        }
      );
    })

  }

  
}
