import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterFormComponent } from '../body/product-list/filter-form.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  @Input() opened: boolean
  @Input() isUserConnected: boolean;
  @Input() userPseudo: string;
  @ViewChild(FilterFormComponent) filterFormComponent: FilterFormComponent

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.opened = false; 
  }

  onSignOut(): void {
    this.authService.signOutUser()
    .then(() => {
      location.reload();
    })
    .catch((error) => {
      console.error(error);
    })
  }

}
