import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterDisplayService {
  initialized: boolean = false; 
  isCategoryDisplayInitialized: boolean; 
  isBrandDisplayInitialized: boolean;
  categoryDisplay: Subject<any> = new Subject;
  brandDisplay: Subject<any> = new Subject;

  constructor() { }

  isInitialized() {
    return new Promise ((resolve, reject) => {
      if (this.initialized) {
        resolve(true);
      }
    })
  }

  updateCategoryDisplay(dictOfCategories: any) {
    this.categoryDisplay.next(dictOfCategories);
  }
  updateBrandDisplay(brandAll: boolean, brandList: any) {
    this.brandDisplay.next([brandAll, brandList]);
  }
}
