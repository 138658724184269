import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-admin-edit-product',
  templateUrl: './admin-edit-product.component.html'
})
export class AdminEditProductComponent implements OnInit {
  product: any;
  attributeList: any[];
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.attributeList = [];
    this.product = this.config['data']['product'];
    for (let attributeKey in <Object> this.product) {
      this.attributeList.push({
        key: attributeKey,
        value: this.product[attributeKey]
      })
    }
  }

}
