import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/product.service';
import * as firebase from 'firebase/app'; 
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';


@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit {
  @Input() productId: string; 
  @Input() product: any; 
  newFeature: string = ''; 
  newValue: string = '';
  displayedColumns: string[] = ['feature', 'value'];
  dataSource: any[];
  showTable2: boolean = false; 
  categoryList: any; 
  productCategory: any; 
  productSubcategory: any;
  subcategoryList: any;
  categoriesList: any; 
  optionsFeature = ["category", "subcategory"]; 
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar

  ) { }

  ngOnInit(): void {
    this.showTable2 = false;
    this.productId =  this.route.snapshot.params['id'];
    this.categoryList = [];
    this.subcategoryList = [];
    this.productService.getCategories()
      .then((list) => {
        this.categoriesList = list; 
        for(let category in <Object>list) {
          this.categoryList.push(category);
          list[category].forEach(subcategory => {
            this.subcategoryList.push(subcategory);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
    this.dataSource = []
    this.productService.getProductNew(this.productId).then((product) => {
      this.product = product; 
      this.productCategory = product['category'];
      this.productSubcategory = product['subcategory'];
      for (let feature in this.product) {
        if(feature != 'comments') {
          this.dataSource.push({
            feature: feature,
            value: this.product[feature]
            });
        }
        }
      this.dataSource.sort();
      this.showTable2 = true; 
      })
      .catch((error) => {
        console.log(error);
      });
  }

  openSnackBar(text: string) {
    this._snackBar.open(text, "Close", {duration: 5000});
  }

  onEditProduct(feature: string, value: string, del?: boolean) {
    this.productService.editProduct(this.productId, feature, value, del)
    .then((message) => {
      this.openSnackBar(<string> message);
      this.ngOnInit();
    })
    .catch((error) => {
      this.openSnackBar(error);
      
    });
  }

}
