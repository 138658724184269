import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/product.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-likes',
  templateUrl: './likes.component.html',
  styleUrls: ['./likes.component.css']
})
export class LikesComponent implements OnInit {
  likes: []; 
  displayedColumns: string[] = ['brand', 'name', 'link'];
  dataSource: any[];
  showTable: boolean = false; 

  constructor(
    private userService: UserService, 
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.dataSource = [];
    this.likes  = this.userService.getLocalStorageLikes();
    console.log(this.likes);
    for (let productId in this.likes) {
      if(this.likes[productId]) {
        this.productService.getProduct(productId).then((product) => {
          product['link'] = "/products/" + productId;
          this.dataSource.push(product);
          this.showTable = true; 
        }, 
        (error)=> {
          ;
        });
      };
      }

  }
}
