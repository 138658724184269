import { Injectable } from '@angular/core';
import { Product } from './product.model'
import * as firebase from 'firebase/app';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProductService {
  dictOfProductsByCategories: any;
  dictOfCategories: any;
  dictOfProducts: any;
  categoryList: any[];
  productList: Promise<Object>;
  productListClassic: Object;
  brandList: any[];
  brandListObservable: Subject<any> = new Subject();
  categoryObjectObservable: Subject<any> = new Subject();
  productObjectObservable: Subject<any> = new Subject();
  constructor() { }

  getAllProductsByCategories(){
    return new Promise((resolve, reject) => {
      if (this.dictOfProductsByCategories) {
        this.categoryObjectObservable.next(this.dictOfCategories);
        this.brandListObservable.next(this.brandList);
        resolve(this.dictOfProductsByCategories);
      }
      else {
        this.brandList = [];
        this.getCategories()
          .then((categories) => {
            this.categoryObjectObservable.next(categories);
            let dictOfProductsByCategories = {};
            for (let category in <Object> categories) {
              dictOfProductsByCategories[category] = {};
              for (let subcategory of categories[category]) {
                dictOfProductsByCategories[category][subcategory] = {};
              }
            }

            this.getAllProductsNew()
              .then((dictOfProducts) => {
                for (let productId in <Object> dictOfProducts) {
                  let product = dictOfProducts[productId];
                  dictOfProductsByCategories[product.category][product.subcategory][productId] = new Object(product);
                  let brand = product['brand'];
                  if (this.brandList.indexOf(brand) === -1) {
                    this.brandList.push(brand);
                  }
                }
                this.brandListObservable.next(this.brandList);
                this.dictOfProductsByCategories = {};
                this.dictOfProductsByCategories = dictOfProductsByCategories;

                resolve(dictOfProductsByCategories);

              })
              .catch((error) => {
                reject(error);
              })

          })
          .catch((error) => {
            reject(error);
          })
      }
    })
  }


  /**
   * Returns a promise resolved with a dict of categories
   *
   * @return {*}
   * @memberof ProductService
   */
  getCategories(){
    return new Promise<Object | string>((resolve, reject) => {
      if(this.dictOfCategories) {
        resolve(this.dictOfCategories);
      }
      else {
        let categoriesRef = firebase.default.firestore().collection("categories").doc("dictOfCategories")
        categoriesRef.get()
        .then((value) => {
          this.dictOfCategories = value.data();
          this.categoryObjectObservable.next(this.dictOfCategories);
          resolve(this.dictOfCategories);
        })
        .catch((error) => {
          reject(error);
        })
      }

    })

  }

  getAllProductsNew() {

    return new Promise((resolve, reject) => {
      if(this.dictOfProducts) {
        resolve(this.dictOfProducts);
      }
      else {
        this.dictOfProducts = {};
        let productsRef = firebase.default.firestore().collection("products")
        productsRef.get()
        .then((value) => {
          value.forEach((doc) => {
            let product = {};
            product[doc.id] = doc.data();
            this.dictOfProducts[doc.id] = doc.data();
          })
          this.productObjectObservable.next(this.dictOfProducts)
          resolve(this.dictOfProducts);
        })
        .catch((error) => {
          reject(error);
        })
      }

    })
  }

  getBrands(): Promise<string[] | string> {
    return new Promise ((resolve,reject) => {
      if(this.brandList) {
        resolve(this.brandList)
      }
      else {
        reject("No brand list available");
      }
    })
  }

  getProduct(id: string) {
    return new Promise ((resolve, reject) => {
      if (this.dictOfProducts) {

        resolve(this.dictOfProducts[id]);
      }
      else {
        this.getAllProductsNew().then((data) => {
          resolve(this.dictOfProducts[id]);
        })
      }
    })
  };

  getProductNew(id: string) {
    return new Promise ((resolve, reject) => {
      firebase.default.firestore().collection("products").doc(id).get()
        .then((snapshot) => {
          resolve(snapshot.data());
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  createProduct(product: Product) {
    return new Promise ((resolve, reject) => {
      let objectValues = product.toJSONtoDB();
      firebase.default.firestore().collection("products").doc(product.id).set(objectValues)
        .then(() => {
          var message = "Product has been created";
          resolve(<string> message);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  editProduct(productId: string, feature: string, value: string, del?:boolean) {
    return new Promise((resolve, reject) => {
      if (del) {
        firebase.default.firestore().collection("products").doc(productId).update({
          [feature]: firebase.default.firestore.FieldValue.delete()
        })
        .then(() => {
          var message = "The feature " + feature + " has been removed from " + productId;
          resolve(message);
        })
        .catch((error) => {
          reject(error);
        })
      }
      else {
        firebase.default.firestore().collection("products").doc(productId).update({
          [feature]: value
        })
        .then(() => {
          var message = "The feature " + feature + " has been changed to " + value;
          resolve(message);
        })
        .catch((error) => {
          reject(error);
        })
      }
    })
  }

  likeProduct(id: string, value: number) {
    console.log(value)
    firebase.default.firestore().collection("products").doc(id).update({
      likes: value
    })
  }

  unlikeProduct(id: string, value: number) {
    firebase.default.firestore().collection("products").doc(id).update({
      likes: value
    })  }

  getComments(productId: string) {
    return new Promise ((resolve, reject) => {
      let productRef = firebase.default.firestore().collection("products").doc(productId).get()
        .then((snapshot) => {
          let comments = snapshot.data().comments;
          resolve(comments);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  setComment(productId: string, authorId: string, authorPseudo: string, rating: number, content: string) {
    return new Promise ((resolve, reject) => {
      let comment = {
        authorId: authorId,
        authorPseudo: authorPseudo,
        rating: rating,
        content: content
      }

      let commentForUser = {
        productId: productId,
        rating: rating,
        content: content
      }
      let productRef = firebase.default.firestore().collection("products").doc(productId);
      let userRef= firebase.default.firestore().collection("users").doc(authorId);

      productRef.update({
        comments: firebase.default.firestore.FieldValue.arrayUnion(comment)
      })
        .then(() => {
          userRef.update({
            comments: firebase.default.firestore.FieldValue.arrayUnion(commentForUser)
          })
            .then(() => {
            resolve();
          })
            .catch((error) => {
              reject(error);
            })
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  removeComment(productId: string, authorId: string, authorPseudo: string, rating: number, content: string) {
    return new Promise ((resolve, reject) => {
      let comment = {
        authorId: authorId,
        authorPseudo: authorPseudo,
        rating: rating,
        content: content
      }

      let commentForUser = {
        productId: productId,
        rating: rating,
        content: content
      }
      let productRef = firebase.default.firestore().collection("products").doc(productId);
      let userRef= firebase.default.firestore().collection("users").doc(authorId);

      productRef.update({
        comments: firebase.default.firestore.FieldValue.arrayRemove(comment)
      })
        .then(() => {
          userRef.update({
            comments: firebase.default.firestore.FieldValue.arrayRemove(commentForUser)
          })
            .then(() => {
              resolve();
          })
            .catch((error) => {
              reject(error);
            })
        })
        .catch((error) => {
          reject(error);
        })
    })
  }
}
