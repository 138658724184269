import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './layout/header.component';
import { MainComponent } from './main/main.component';
import { ProductListComponent } from './body/product-list/product-list.component';
import { ProductComponent } from './body/product-list/product/product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductDetailsComponent } from './body/product-list/product/product-details/product-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatExpansionModule } from '@angular/material/expansion';
import { ProductShowComponent } from './body/product-list/product/product-show/product-show.component';
import { LoginComponent } from './auth/login/login.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SearchComponent } from './body/search/search.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTabsModule} from '@angular/material/tabs';
import { DialogComponent } from './body/dialog/dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatChipsModule} from '@angular/material/chips';
import { ResetComponent } from './auth/reset/reset.component';
import {MatMenuModule} from '@angular/material/menu';
import { LikesComponent } from './profile/likes/likes.component';
import {MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import { EditProductComponent } from './body/product-list/product/edit-product/edit-product.component';
import { CreateProductComponent } from './body/create-product/create-product.component';
import { CategoryShowComponent } from './body/product-list/category-show/category-show.component';
import { CommentComponent } from './body/product-list/product/comment/comment.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';

import { RessourcesComponent } from './body/product-list/product/ressources/ressources.component';
import { LeaveCommentComponent } from './body/product-list/product/comment/leave-comment.component';
import { CommentdivComponent } from './body/product-list/product/comment/comment-div.component'
import {MatSelectModule} from '@angular/material/select';
import { SidenavComponent } from './layout/sidenav.component';
import { CategoryTableComponent } from './body/product-list/category-show/category-table.component';
import { CategoryGridComponent } from './body/product-list/category-show/category-grid.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { FilterFormComponent } from './body/product-list/filter-form.component';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { AdminComponent } from './admin/admin.component';
import {SidebarModule} from 'primeng/sidebar';
import { AdminTableComponent } from './admin/admin-table.component';
import {TableModule} from 'primeng/table';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import { ProductShowDialogComponent } from './body/product-list/product/product-show/product-show-dialog.component';
import { AdminEditProductComponent } from './admin/admin-edit-product.component';
import {SkeletonModule} from 'primeng/skeleton';
import {TooltipModule} from 'primeng/tooltip';
import {EditorModule} from 'primeng/editor';




@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MainComponent,
    ProductListComponent,
    ProductComponent,
    ProductDetailsComponent,
    ProductShowComponent,
    LoginComponent,
    SearchComponent,
    DialogComponent,
    ResetComponent,
    LikesComponent,
    EditProductComponent,
    CreateProductComponent,
    CategoryShowComponent,
    CommentComponent,
    LeaveCommentComponent,
    CommentdivComponent,
    RessourcesComponent,
    SidenavComponent,
    CategoryTableComponent,
    CategoryGridComponent,
    FilterFormComponent,
    AdminComponent,
    AdminTableComponent,
    ProductShowDialogComponent,
    AdminEditProductComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule, BrowserAnimationsModule,
    MatSliderModule, MatSidenavModule, MatButtonModule, MatCheckboxModule,
    MatToolbarModule, MatExpansionModule, MatFormFieldModule, MatInputModule,
    MatProgressSpinnerModule, MatAutocompleteModule, MatTooltipModule,
    MatDialogModule, MatMenuModule, MatTableModule, MatSnackBarModule, MatCardModule,
    MatChipsModule, MatTabsModule, MatDividerModule, MatIconModule, MatSelectModule, MatButtonToggleModule,
    SidebarModule, TableModule, DynamicDialogModule, SkeletonModule,TooltipModule, EditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
