<div style="display: flex;">
  <div class="card" style="width: 300px; margin: 5px;">
    <p-table [value]="attributeList" styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="400px" [resizableColumns]="true">
      <ng-template pTemplate="colgroup">
        <colgroup>
            <col>
            <col>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header"  sortMode="multiple">
          <tr style="font-size: 12px; height: 30px!important; font-family: 'Montserrat', sans-serif;">
              <th pSortableColumn="field" pResizableColumn>Field<p-sortIcon field="field"></p-sortIcon></th>
              <th pSortableColumn="value" pResizableColumn>Value<p-sortIcon field="value"></p-sortIcon></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-attribute>
          <tr style="font-size: 12px; font-family: 'Roboto', sans-serif;">
              <td >{{attribute.key}}</td>
              <td pEditableColumn>
                <p-cellEditor>
                    <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="attribute.value">
                    </ng-template>
                    <ng-template pTemplate="output">
                        {{attribute.value}}
                    </ng-template>
                </p-cellEditor>
            </td>
          </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="card" style="margin: 5px; border: none  ;">
    <h5 style="font-family: 'Montserrat', sans-serif;">Edit description</h5>
    <p-editor [(ngModel)]="product['description']" [style]="{'height':'300px'}">
      <ng-template pTemplate="header">
        <span class="ql-format-group">
            <span title="Bold" class="ql-format-button ql-bold"></span>
            <span class="ql-format-separator"></span>
            <span title="Italic" class="ql-format-button ql-italic"></span>
            <span class="ql-format-separator"></span>
            <span title="Underline" class="ql-format-button ql-underline"></span>
            <span class="ql-format-separator"></span>
            <span title="Strikethrough" class="ql-format-button ql-strike"></span>
        </span>
    </ng-template>
    </p-editor>
  </div>
</div>
