<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div class="col-12" style="margin: 20px;">
      <h2 style="font-family: 'Montserrat', sans-serif;">Admin Center</h2>
    </div>
    <div class="col-10 mx-auto" style="text-align: center">
    <app-admin-table></app-admin-table>
    </div>
  </div>
</div>
