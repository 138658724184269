import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/product.service';
import {DialogService} from 'primeng/dynamicdialog';
import { ProductShowDialogComponent } from '../body/product-list/product/product-show/product-show-dialog.component';
import { AdminEditProductComponent } from './admin-edit-product.component';


@Component({
  selector: 'app-admin-table',
  templateUrl: './admin-table.component.html',
  providers: [DialogService]
})
export class AdminTableComponent implements OnInit {
  products: any[] = [];
  isTableLoaded: boolean = false;
  constructor(private productService: ProductService, public dialogService: DialogService) { }

  ngOnInit(): void {
    this.products = [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}];
    this.isTableLoaded = false;
    this.productService.getAllProductsNew()
      .then((list) => {
        this.products = [];
        for (let productKey in <Object> list) {
          var product = list[productKey];
          product["id"] = productKey;
          this.products.push(list[productKey])
        }
        this.isTableLoaded = true;
      })
      .catch((error) => {
        console.error(error);
      })
  }

  showProduct(productId) {
    const ref = this.dialogService.open(ProductShowDialogComponent, {
      width:'90%',
      dismissableMask: true,
      showHeader: false,
      data: {
          productId: productId,
    }});
  }

  editProduct(product) {
    const ref = this.dialogService.open(AdminEditProductComponent, {
      width:'70%',
      dismissableMask: true,
      header: `Edit ${product['brand']} ${product['name']}`,
      data: {
          product: product,
      }
    });
  }

}
