import { Component, Input, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit {
  @Input() title: string; 
  @Input() button: string; 
  constructor() { }

  ngOnInit(): void {
  }

}
