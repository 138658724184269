import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-category-table',
  templateUrl: './category-table.component.html',
  styleUrls: ['./category-table.component.css']
})
export class CategoryTableComponent implements OnInit, AfterViewInit {
  @Input() productList: Object;
  displayedColumns: string[] = ['brand', 'name', 'description', 'network', 'link'];
  dataSource: MatTableDataSource<any>;
  showTable: boolean = false; 
  @ViewChild(MatSort) sort: MatSort;


  constructor() { }

  ngOnInit(): void {
    let dataSourceTemp =  []
    for (let productId in this.productList) {
      dataSourceTemp.push(this.productList[productId]);
    }
    this.dataSource = new MatTableDataSource(dataSourceTemp)
    this.showTable = true; 
  }

  ngAfterViewInit() {
    console.log("Log this shit")
    this.dataSource.sort = this.sort;
  }
}
