<mat-sidenav-container style="height: 90vh;">
    <mat-sidenav mode="side" [opened]="opened" class="sidenav-custom">
        <div class="hello-div-mobile">
            <p class="hello-text-mobile" *ngIf="isUserConnected" >Hi, {{ userPseudo }}</p>
            <button *ngIf="isUserConnected" mat-stroked-button class="login-button-mobile" routerLink="/"(click)="onSignOut()">Sign out</button>
            <button *ngIf="!isUserConnected" mat-stroked-button class="login-button-mobile" routerLink="/login">Log-in<span class="material-icons" style="font-size: 28px; margin-left: 5px;">
                account_circle
            </span></button>
        </div>
        <div style="display: flex; margin: 20px auto; padding: 5px; ">
          <app-search class="mx-auto app-search"></app-search>
        </div>
        <div class="navigation-div">
            <mat-expansion-panel hideToggle style="margin: 20px 0px; padding: 5px;" class="mat-custom-class">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                      <h6><span  class="mdi mdi-magnify" style="margin-right: 10px;"></span>CATEGORIES</h6>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                    <button class="nav-button" mat-menu-item [matMenuTriggerFor]="devicesequipments"><h5 class="nav-menu-item">Devices & Equipments</h5></button>
                    <button mat-menu-item [matMenuTriggerFor]="infrastructure"><h5 class="nav-menu-item">Infrastructure</h5></button>
                    <button mat-menu-item [matMenuTriggerFor]="sensorscontrollers"><h5 class="nav-menu-item">Sensors & Controllers</h5></button>
                    <button mat-menu-item [matMenuTriggerFor]="software"><h5 class="nav-menu-item">Software</h5></button>
                      <mat-menu #devicesequipments="matMenu">
                        <button class="nav-button" mat-menu-item routerLink="/categories/bulbs&lights"><h5 class="nav-menu-item">Bulbs & lights</h5></button>
                        <button mat-menu-item routerLink="/categories/speakers"><h5 class="nav-menu-item">Speakers</h5></button>
                        <button mat-menu-item routerLink="/categories/vacuum&moprobots"><h5 class="nav-menu-item">Vacuum & Mop robots</h5></button>
                      </mat-menu>
                      <mat-menu #sensorscontrollers="matMenu">
                        <button class="nav-button" mat-menu-item routerLink="/categories/door&windowsensors"><h5 class="nav-menu-item">Door & Window sensors</h5></button>
                        <button mat-menu-item routerLink="/categories/flood&waterleaksensors"><h5 class="nav-menu-item">Flood & Water leak sensors</h5></button>
                        <button mat-menu-item routerLink="/categories/motion&vibrationsensors"><h5 class="nav-menu-item">Motion & vibration sensors</h5></button>
                        <button mat-menu-item routerLink="/categories/switches&dimmers"><h5 class="nav-menu-item">Switches & Dimmers</h5></button>
                        <button mat-menu-item routerLink="/categories/temperature&humiditysensors"><h5 class="nav-menu-item">Temperature & Humidity sensors</h5></button>
                      </mat-menu>
                      <mat-menu #software="matMenu">
                        <button class="nav-button" mat-menu-item routerLink="/categories/automation"><h5 class="nav-menu-item">Automation</h5></button>
                        <button mat-menu-item routerLink="/categories/virtualassistant"><h5 class="nav-menu-item">Virtual Assistant</h5></button>
                      </mat-menu>
                      <mat-menu #infrastructure="matMenu">
                        <button class="nav-button" mat-menu-item routerLink="/categories/micro-chips&micro-controllers"><h5 class="nav-menu-item">Micro-chips & Micro-Controllers</h5></button>
                        <button mat-menu-item routerLink="/categories/routers&switches"><h5 class="nav-menu-item">Routers & Switches</h5></button>
                      </mat-menu>
            </mat-expansion-panel>
          </div>
        <app-filter-form></app-filter-form>
        <div class="contact-div mx-auto">
          <a href="mailto:clement@petitimbert.com"><h6 class="contact">Contact</h6></a>
          <h6 class="copyright">Copyright 2020</h6>
        </div>
    </mat-sidenav>
</mat-sidenav-container>