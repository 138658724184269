import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app'
import 'firebase/storage';
import { Upload } from './upload';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  basePath: string = '/images';
  uploadTask: firebase.default.storage.UploadTask;

  constructor() { }

  pushUpload(upload: Upload) {
    let storageRef = firebase.default.storage().ref();
    this.uploadTask = storageRef.child(`${this.basePath}/${upload.file.name}`).put(upload.file);

    this.uploadTask.on(firebase.default.storage.TaskEvent.STATE_CHANGED, 
      (snapshot) => {
        // upload in progress
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) *100; 
      },
      (error) => {
        console.log(error);
      }, 
      () => {
        // upload success
        storageRef.child(`${this.basePath}/${upload.file.name}`).getDownloadURL()
          .then((url) => {
            upload.url = url; 
          })
          .catch((error) => {
            console.log(error);
          });
        
        upload.name = upload.file.name; 

        })
      }
}
