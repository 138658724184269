import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/product.service';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
import { UserService } from 'src/app/services/user.service';
import { DialogComponent } from 'src/app/body/dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';


@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  @Input() productId: string;
  @Input() product: any;
  ratingsList: Number[];
  ratingMean: Number | string;
  likedByUser: boolean = false;
  @ViewChild(DialogComponent) DialogComponent: DialogComponent


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private userService: UserService,
    public dialog: MatDialog
    ) { }

  ngOnInit(): void {

    // Set the url to icon on local
    this.product['iconUrl'] = "assets/" + this.product['imageUrl'];

    if (this.userService.getLocalStorageConnected()) {
      this.likedByUser = this.userService.getLocalStorageLikes()[this.productId];
    }
    this.ratingsList = []
    let ratingSum = 0;
    if(this.product['comments'] && this.product['comments'].length != 0) {
      let comments = this.product['comments'];
      comments.forEach(comment=> {
        ratingSum = ratingSum + comment['rating'];
      });
      this.ratingMean = comments.lentgh != 0 ? ratingSum / comments.length : 0;
    }
  };

  onLike() {
    if (!this.userService.getLocalStorageConnected()) {
      this.openDialog();
      return false;
    }
    if (!this.likedByUser) {
      this.product['likes']++;
    }
    else {
      this.product['likes']--;

    }
    this.likedByUser = !this.likedByUser;
    this.productService.unlikeProduct(this.productId, this.product['likes']);
    this.userService.onLikeProduct(this.productId, this.likedByUser);
  };

  openProduct() {
    this.router.navigate(['products/' + this.productId]);
  }

    onClick(event) {
      if (event.target.id == "heart"){
        this.onLike()
      }
      else {
        this.openProduct();
      }
    }

    openDialog() {
      this.dialog.open(DialogComponent);
    }
}
