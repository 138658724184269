import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/product.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-category-show',
  templateUrl: './category-show.component.html',
  styleUrls: ['./category-show.component.css']
})
export class CategoryShowComponent implements OnInit {
  categoryName: string; 
  subcategoryId: string;
  subcategoryName: string;
  productList: any;
  isLoaded: boolean = false; 
  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private productService: ProductService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.subcategoryId = params['id'];
      this.isLoaded = false; 
      this.initView(this.subcategoryId)
    })

  }

  initView(id: string) {
    this.productList = [];
    this.productService.getAllProductsNew()
    .then((dict) => {
      for (let product in <Object> dict) {
        if(dict[product]['subcategory'].toLowerCase().replace(/\s+/g, '') == this.subcategoryId) {
          this.productList[product] = dict[product];
        }
      }
      if (Object.keys(this.productList).length == 0) {
        this.router.navigate(['']);
      }
      else {
        this.subcategoryName = this.productList[Object.keys(this.productList)[0]]['subcategory'];
        this.isLoaded = true; 
      }

    })
  }

  back(){
    this._location.back();
  }

}
