<div *ngIf="show" class="leave-comment-div">
    <h6>Leave a review on this product</h6>
    <mat-icon *ngFor="let count of [1,2,3,4,5]" 
        class="star"
        [ngClass]="{'star-yellow': starsDisplay[count - 1]}"
        (mouseenter)="onOverStar(count)" 
        (mouseleave)="onLeaveStar()"
        (click)="onClickStar(count)" 
        [id]="count">grade</mat-icon>
    <div style="display: flexbox; margin-top: 5px;" >
        <textarea class="text-input" type="text" [formControl]="comment" placeholder="You can leave a comment here"></textarea>
        <button mat-raised-button color="primary" (click)="submitComment()" style="margin-left: 5px;" [disabled]="!isRatingSubmitted">Submit</button>
    </div>
</div>

