import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/product.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { UserService } from 'src/app/services/user.service';
import * as firebase from 'firebase/app';
import 'firebase/auth'
import { SearchComponent } from '../search/search.component';
import { FilterDisplayService } from 'src/app/services/filter-display.service';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({opacity: 0 }),
            animate('0.1s ease-out', 
                    style({opacity: 1}))
          ]
        ),
        transition(
          ':leave', 
          [
            style({opacity: 1 }),
            animate('0.1s ease-in', 
                    style({opacity: 0 }))
          ]
        )
      ]
    ),
    trigger(
      'rightToLeftAnimation', 
      [
        transition(
          ':enter',
          [
            style({transform: 'translateX(0%)'}),
            animate('3s ease-out', 
            style({transform: 'translateX(100%)'}))
          ]
        ),
        transition(
          ':leave',
          [
            style({transform: 'translateX(100%)'}),
            animate('3s ease-in', 
            style({transform: 'translateX(0%)'}))
          ]
        )        
      ]
    )
  ]
})
export class ProductListComponent implements OnInit {

  productList: any;
  categoryList = new Object();
  categoryDisplay = {};
  categoryIndexes = {};
  productDisplay: any; 
  brandDisplay = {};
  brandDisplayAll: boolean = true;
  brandList: string[];
  indexMax: number = 6; 
  indexMin: number = 0; 
  isLoaded: boolean = false;
    
  constructor(
    private productService: ProductService, 
    private router: Router,
    private filterDisplayService: FilterDisplayService
    ) { }

  ngOnInit() {

    this.productService.categoryObjectObservable.subscribe(categoryList => {
      this.setCategoryLists(categoryList);
    })

    this.filterDisplayService.categoryDisplay.subscribe(val => {
      this.categoryDisplay = val;
    })

    this.filterDisplayService.brandDisplay.subscribe(val => {
      this.brandDisplayAll = val[0];
      this.brandDisplay = val[1];

      if(this.productList) {
        this.setCategoryDisplay();
      }
    })

    this.productService.getAllProductsByCategories()
    .then((list) => {
      this.productList = new Object(list);
      this.productDisplay = new Object(list);

      this.filterDisplayService.isInitialized().then(() => {
        this.setCategoryDisplay();
        this.isLoaded = true;
      })
    })
    .catch((error)=> {
      console.error(error);
    })
  
  }

  setCategoryLists(list) {
    // Setting component property with the list extracted from DB
    for (var cat in list) {
      // Each main category is a key
      this.categoryList[cat] = new Array();
      for (var subCat in list[cat]) {
        this.categoryList[cat].push(subCat);
      }
    };
  };

  setCategoryDisplay() {
    this.productDisplay = this.productList;
    // Setting component property with the list extracted from DB
    let newProductDisplay = {};
    for (let cat in this.productDisplay) {    
      newProductDisplay[cat] = {};
      for (let subCat in this.productDisplay[cat]) {
        let counter = 0; 
        newProductDisplay[cat][subCat] = {};
        for(let product in this.productDisplay[cat][subCat]){
          let productValue = this.productDisplay[cat][subCat][product];
          if(this.brandDisplay[productValue['brand']])
          { 
            counter++;
            newProductDisplay[cat][subCat][product] = productValue;
          }
          else {
          }         
        }
        // Array of sub-categories in category value
        this.categoryIndexes[subCat] = {'test': 'test'}
        this.categoryIndexes[subCat]['currentIndexMin'] = 0;
        this.categoryIndexes[subCat]['currentIndexMax'] = 6;
        this.categoryIndexes[subCat]['indexMax'] = counter;
      }
    }
    this.productDisplay = newProductDisplay;

    }


  openProduct(id: string) {
    this.router.navigate(['products/' + id])
  }

  onClickNext(subcategory: string) {
    let currentIndexMax = this.categoryIndexes[subcategory]['currentIndexMax'];
    let indexMax = this.categoryIndexes[subcategory]['indexMax'];
    if (currentIndexMax < indexMax) {
      this.categoryIndexes[subcategory]['currentIndexMax']++;
      this.categoryIndexes[subcategory]['currentIndexMin']++;
    }
  }
  onClickBack(subcategory: string) {
    let currentIndexMin = this.categoryIndexes[subcategory]['currentIndexMin'];
    if (currentIndexMin > 0) {
      this.categoryIndexes[subcategory]['currentIndexMax']--;
      this.categoryIndexes[subcategory]['currentIndexMin']--;
    }
  }

  toCategoriesURI(string: string): string {
    return "/categories/" + string.toLowerCase().replace(/\s+/g, '') ;
  }

}
