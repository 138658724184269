<div class="card">
    <p-table [value]="products" styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollHeight="400px" [resizableColumns]="true">
      <ng-template pTemplate="colgroup">
        <colgroup>
            <col>
            <col>
            <col>
            <col>
        </colgroup>
      </ng-template>
      <ng-template pTemplate="header"  sortMode="multiple">
          <tr style="font-size: 12px; height: 30px!important; font-family: 'Montserrat', sans-serif;">
              <th pSortableColumn="brand" pResizableColumn>Brand<p-sortIcon field="brand"></p-sortIcon></th>
              <th pSortableColumn="name" pResizableColumn>Product<p-sortIcon field="name"></p-sortIcon></th>
              <th pSortableColumn="category" pResizableColumn>Category<p-sortIcon field="category"></p-sortIcon></th>
              <th pSortableColumn="subcategory" pResizableColumn>Subcategory<p-sortIcon field="subcategory"></p-sortIcon></th>
              <th style="width: 70px;">Actions</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-product>
        <tr *ngIf="!isTableLoaded">
            <td><p-skeleton></p-skeleton></td>
            <td><p-skeleton></p-skeleton></td>
            <td><p-skeleton></p-skeleton></td>
            <td><p-skeleton></p-skeleton></td>
        </tr>
        <tr *ngIf="isTableLoaded" style="font-size: 12px; font-family: 'Roboto', sans-serif;">
            <td>{{product.brand}}</td>
            <td>{{product.name}}</td>
            <td>{{product.category}}</td>
            <td>{{product.subcategory}}</td>
            <td style="width: 70px;"><a (click)="showProduct(product.id)" pTooltip="View product" style="margin: 2px 2px; cursor:pointer" showDelay="500"><i class="pi pi-eye"></i></a><a style="margin: 2px 2px; cursor: pointer;" (click)="editProduct(product)" pTooltip="Edit product" showDelay="500"><i class="pi pi-pencil"></i></a></td>
        </tr>
      </ng-template>
    </p-table>
</div>
