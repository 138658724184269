<app-header [isOnHomePage]="false"></app-header>
<div *ngIf="isLoaded" class="container-fluid">
  <!-- <button class="btn custom-button" (click)="back()"><i class="fas fa-chevron-circle-left" ></i></button> -->
    <div class="row justify-content-start" style="margin: 10px">
        <div class="col-sm-12" style="margin-right: 100px;" style="display: flex; margin-bottom: 10px;">
          <h4 class="category-name">{{ subcategoryName }}</h4>
            <mat-button-toggle-group #group="matButtonToggleGroup" value="grid" class="button-view-mode" style="position: absolute; right: 10px;">
                <mat-button-toggle value="grid" matTooltip="Show in grid mode">
                  <mat-icon>grid_on</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="table" aria-label="List" matTooltip="Show in table mode">
                  <mat-icon>list</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <app-category-grid *ngIf="group.value != 'table'" [productList]="productList"></app-category-grid>
    <app-category-table *ngIf="group.value == 'table'" [productList]="productList"></app-category-table>
</div>
