import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import {FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  resetPasswordForm : FormGroup;
  mode: string; 
  oob: string;
  email: string; 
  codeChecked: boolean;
  errorCode: string; 
  hide: boolean = true; 
  showSpinner: boolean = false; 

  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private authService: AuthService, 
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(){

    this.initForm();

    this.email = '';
    this.activatedRoute.queryParams.subscribe(params => {

      // Re-route to home if no query params are received
      if (Object.entries(params).length == 0) {
        this.router.navigate(['/']);
      }

      // Retrieve mode and oob from the params
      this.mode = params['mode'];
      this.oob = params['oobCode'];
      if (this.mode == "resetPassword") {
        this.authService.verifyOob(this.oob).then(
          (email) => {
            this.codeChecked = true; 
            this.email = <string> email; 
          },
          (error) => {
            this.codeChecked = false; 
            this.errorCode = error.message; 
          }
        );
      }
      else {
        this.router.navigate(['/']);
      }
    })
  }

  initForm(){
    this.resetPasswordForm = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.email]],
      newPassword: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]]
    });
  }

  onSubmitResetPasswordForm() {
    this.showSpinner = true; 
    const newPassword = this.resetPasswordForm.get('newPassword').value;
    this.authService.resetPassword(this.oob, newPassword).then(
      () => {
        console.log("Success");
        this.router.navigate(['/login']);
      })
    .catch(
      (error) => {
        this.errorCode = error;
      }
    )
  }



}