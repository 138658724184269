<mat-toolbar class="bg-dark">
    <button mat-icon-button  routerLink="/"><span class="material-icons" style="color: white">home</span></button>
    <a routerLink="/" ><h1 class="site-title">Home Automation</h1></a>
</mat-toolbar>
<div class="container-fluid" style="margin-top: 30px; height: 90vh">
    <div class="row">
        <div class="col-md-8 mx-auto">
            <h6 style="text-align: center;">Add a new product</h6>
            <form [formGroup]="newProductForm" *ngIf="isLoaded" >
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label for="id">Product Id</mat-label>
                    <input matInput formControlName="id" style="height: 20px!important;" aria-label="id">
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Brand</mat-label>
                    <input matInput formControlName="brand" style="height: 20px!important;">
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="name" style="height: 20px!important;">
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" style="height: 20px!important;">
                </mat-form-field>
                <!-- <label>Category</label>
                <select formControlName="category">
                    <option *ngFor="let cat of categoriesList | keyvalue" [value]="cat.key">{{ cat.key }}</option>
                </select> -->
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Category</mat-label>
                    <mat-select formControlName="category" [(value)]="category">
                        <mat-option *ngFor="let cat of categoriesList | keyvalue" [value]="cat.key">{{ cat.key }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Subcategory</mat-label>
                    <mat-select formControlName="subcategory">
                        <mat-option *ngFor="let subcat of categoriesList[category]" [value]="subcat">{{ subcat }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Category</mat-label>
                    <input matInput formControlName="category" style="height: 20px!important;" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of categories" [value]="option">
                          {{option}}
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field> -->
                <!-- <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Subcategory</mat-label>
                    <input matInput formControlName="subcategory" style="height: 20px!important;"  [matAutocomplete]="auto2">
                    <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                        <mat-option *ngFor="let sub of subcategories" [value]="sub">
                          {{sub}}
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field> -->
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Network</mat-label>
                    <input matInput formControlName="network" style="height: 20px!important;">
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Official Url</mat-label>
                    <input matInput formControlName="officialUrl" style="height: 20px!important;">
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Amazon Url</mat-label>
                    <input matInput formControlName="amazonUrl" style="height: 20px!important;">
                </mat-form-field>
                <mat-form-field appearance="fill" style="width: 100%; height: 70px!important; font-size: 14px;">
                    <mat-label>Image Url</mat-label>
                    <input matInput formControlName="imageUrl" style="height: 20px!important;">
                </mat-form-field>

                <button mat-raised-button color='primary' type="submit" [disabled]="newProductForm.invalid" (click)="onSubmitForm()" style="margin-left: 10px; width: 40%;">
                    <span class="material-icons">
                    add
                    </span>
                    Create Product</button>
                <button mat-raised-button color='primary' type="submit" [disabled]="newProductForm.invalid" (click)="onSubmitFormAndNew()" style="margin-left: 10px; width: 40%;">
                    <span class="material-icons">
                    add
                    </span>
                    Create and add another one</button>
            </form>
            </div>
            <div class="col-md-6 mx-auto" style="margin-top: 40px;">
                <label>
                    <input type="file" (change)="detectFiles($event)">
                </label>
                <button mat-raised-button color='primary' (click)="upload()" [disabled]="!selectedFiles">Upload</button>
                <div *ngIf="currentUpload">
                    <div class="progress">
                        <div class="progress-bar progress-bar-animated" [ngStyle]="{ 'width': currentUpload?.progresss + '%'}"></div>
                    </div>
                    Progress: {{ currentUpload?.name }} | {{ currentUpload?.progress}}% complete
                </div>
            </div>
    </div>
</div>