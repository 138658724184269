<div autosize *ngIf="isLoaded" class="container-fluid" id="catalog-container">
    <ng-container *ngFor= "let category of productDisplay | keyvalue">
        <div *ngIf="categoryDisplay[category.key]" [@inOutAnimation]>
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="category-name">{{ category.key }}</h4>
                </div>
            </div>
            <ng-container *ngFor="let subcategory of category.value | keyvalue" >
                <div *ngIf="categoryDisplay[subcategory.key] || categoryIndexes[subcategory.key]['indexMax'] != 0" style="display: flex;">
                    <div *ngIf="categoryDisplay[subcategory.key] || categoryIndexes[subcategory.key]['indexMax'] != 0" class="button-navigate" >
                        <span *ngIf="categoryIndexes[subcategory.key]['currentIndexMin'] == 0 " style="width: 30px;"></span>
                        <button
                        *ngIf="categoryIndexes[subcategory.key]['currentIndexMin'] != 0"
                        class="btn btn-circle" 
                        style=" float: right; margin: auto;" 
                        (click)="onClickBack(subcategory.key)">
                            <span class="material-icons" style="font-size: 12px;">
                                arrow_back_ios
                            </span>
                        </button>
                    </div>
                    <div *ngIf="categoryDisplay[subcategory.key] || categoryIndexes[subcategory.key]['indexMax'] != 0" [@inOutAnimation] class="row justify-content-start" style="margin: auto 10px 10px 10px; width: 100%;">
                        <div class="col-sm-12">
                            <h6 *ngIf="categoryIndexes[subcategory.key]['indexMax'] != 0" class="subcategory-name" ><a class="subcategory-link" [routerLink]="toCategoriesURI(subcategory.key)" matTooltip="Click to see this category in full page" matTooltipPosition="right">{{ subcategory.key }} ({{ categoryIndexes[subcategory.key]['indexMax'] }})</a></h6>
                        </div>
                        <ng-container *ngFor="let product of subcategory.value | keyvalue; let index = index">
                            <div *ngIf="brandDisplay[product.value.brand] && index < categoryIndexes[subcategory.key]['currentIndexMax'] && index >= categoryIndexes[subcategory.key]['currentIndexMin'];" class="col-6 col-sm-4 col-md-4 col-xl-2" style="padding: 5px;" >
                                <app-product-details
                                [productId]="product.key"
                                [product]="product.value" 
                                >
                                </app-product-details>
                            </div>
                        </ng-container>
                        <div *ngIf="categoryIndexes[subcategory.key]['indexMax'] != 0" class="col-12" style="padding: 5px;">
                            <button *ngIf="categoryIndexes[subcategory.key]['indexMax'] != 0" class="button-view-more" mat-raised-button [routerLink]="toCategoriesURI(subcategory.key)">View more</button>
                        </div>
                    </div>
                    <div *ngIf="categoryDisplay[subcategory.key] || categoryIndexes[subcategory.key]['indexMax'] != 0" class="button-navigate" >
                        <span *ngIf="categoryIndexes[subcategory.key]['indexMax'] < 7 || categoryIndexes[subcategory.key]['currentIndexMax'] == categoryIndexes[subcategory.key]['indexMax']" style="width: 30px;"></span>
                        <button 
                            *ngIf="categoryIndexes[subcategory.key]['indexMax'] >= 7 && categoryIndexes[subcategory.key]['currentIndexMax'] != categoryIndexes[subcategory.key]['indexMax'] && categoryDisplay[subcategory.key]"
                            class="btn btn-circle" 
                            style="float: right; margin: auto;" 
                            (click)="onClickNext(subcategory.key)">
                            <span class="material-icons" style="font-size: 12px;">
                                arrow_forward_ios
                                </span>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
