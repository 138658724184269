import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-grid',
  templateUrl: './category-grid.component.html',
  styleUrls: ['./category-grid.component.css']
})
export class CategoryGridComponent implements OnInit {
  @Input() productList: Object;
  constructor() { }

  ngOnInit(): void {
  }

}
