import { Component, OnDestroy, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductService } from 'src/app/product.service';
import { FilterDisplayService } from 'src/app/services/filter-display.service';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.css']
})
export class FilterFormComponent implements OnInit, OnDestroy {
  isLoaded: boolean = false;
  filterCatForm: FormGroup;
  filterBrandForm: FormGroup;
  categoryDisplay: Object;
  categoryList: Object; 
  brandDisplay: Object;
  brandList: any;
  brandDisplayAll: boolean = true; 
  observer: any

  constructor(
    private formBuilder: FormBuilder, 
    private productService: ProductService,
    private filterDisplayService: FilterDisplayService
  ) { }

  ngOnInit(): void {
    if(this.productService.brandList && this.productService.brandList.length != 0) {
      this.brandList = this.productService.brandList;
      this.initForm();
    }
    else {
      this.productService.brandListObservable.subscribe((val) => {
        this.brandList = val;
        this.initForm();
      })
    }

  }

  initForm() {
    this.productService.getCategories()
    .then((categoryList) => {
      this.categoryList = categoryList;
      // Set variable used as arguments in formBuilder.group
      let groupOfCategory = new Object(); 
      let groupOfBrand = new Object(); 

      for (var cat in <Object> categoryList) {
        groupOfCategory[cat] = true;
        categoryList[cat].forEach(subCat => {
          groupOfCategory[subCat] = true; 
        });
      }

      this.categoryDisplay = groupOfCategory;
      this.filterDisplayService.updateCategoryDisplay(this.categoryDisplay);
      this.filterCatForm = this.formBuilder.group(groupOfCategory);
      for (let brand of this.brandList) {
        groupOfBrand[brand] = true; 
      }
      this.brandDisplayAll = true;
      this.brandDisplay = groupOfBrand;
      this.filterBrandForm = this.formBuilder.group(groupOfBrand);
      this.filterDisplayService.updateBrandDisplay(this.brandDisplayAll, this.brandDisplay);
      this.isLoaded = true; 
      this.filterDisplayService.initialized = true; 

      this.filterBrandForm.valueChanges.subscribe(val2 => {
        setTimeout(() => {
          this.brandDisplay = this.filterBrandForm.value;
          this.filterDisplayService.updateBrandDisplay(this.brandDisplayAll, this.brandDisplay);
        })});
    })
    .catch((error) => {
      console.error(error);
    })



  }

  onChanges(cat: string, isAll: boolean, subcat?: string) {
    if (isAll) {
      this.categoryList[cat].forEach(subCat => {
        let newValue = !this.filterCatForm.get(cat).value; 
        this.filterCatForm.get(subCat).setValue(newValue);
      });
    }
    this.filterCatForm.valueChanges.subscribe(val2 => {
      setTimeout(() => {
        this.categoryDisplay = this.filterCatForm.value;
        if(subcat) {
          if(this.categoryDisplay[subcat]){
            this.categoryDisplay[cat] = true; 
          }
        }
        this.filterDisplayService.updateCategoryDisplay(this.categoryDisplay);
      })});
  }

  onChangeBrands(isAll?: boolean) {
    if (isAll) {
      // Change display for all the brands 
      this.brandDisplayAll = !this.brandDisplayAll;
      for (let brand of this.brandList) {
        this.brandDisplay[brand] = this.brandDisplayAll;
        this.filterBrandForm.get(brand).setValue(this.brandDisplayAll);
      };
      this.filterDisplayService.updateBrandDisplay(this.brandDisplayAll, this.brandDisplay);   
    }
  }

  ngOnDestroy() {
    console.log("Filter form ngondestroy")
  }

}
