<mat-toolbar class="bg-dark" style="height: 10vh">
  <!--
    Button for sidenav, to be uncomment when needed
  <button *ngIf="isOnHomePage" mat-icon-button  (click)="onOpenSidenav()">
      <span *ngIf="!isSidenavOpened" class="material-icons" style="color: white">menu</span>
      <span *ngIf="isSidenavOpened" class="material-icons" style="color: white">close</span>
  </button>
  <button *ngIf="!isOnHomePage" mat-icon-button  routerLink="/"><span class="material-icons" style="color: white">home</span></button> 
  -->
  <!-- Next line to be removed when above comment is removed -->
  <button mat-icon-button routerLink="/"><span class="material-icons" style="color: white">home</span></button> 

  <a routerLink="/"><h1 class="site-title">Home Automation</h1></a>
  <div class="hello-div">
    <div class="navigation-div">
      <h5 class="nav-item" [matMenuTriggerFor]="categories">Categories<span class="material-icons nav-icon">keyboard_arrow_down</span></h5>
        <mat-menu #categories="matMenu">
          <button class="nav-button" mat-menu-item [matMenuTriggerFor]="devicesequipments"><h5 class="nav-menu-item">Devices & Equipments</h5></button>
          <button mat-menu-item [matMenuTriggerFor]="infrastructure"><h5 class="nav-menu-item">Infrastructure</h5></button>
          <button mat-menu-item [matMenuTriggerFor]="sensorscontrollers"><h5 class="nav-menu-item">Sensors & Controllers</h5></button>
          <button mat-menu-item [matMenuTriggerFor]="software"><h5 class="nav-menu-item">Software</h5></button>
            <mat-menu #devicesequipments="matMenu">
              <button class="nav-button" mat-menu-item routerLink="/categories/bulbs&lights"><h5 class="nav-menu-item">Bulbs & lights</h5></button>
              <button mat-menu-item routerLink="/categories/speakers"><h5 class="nav-menu-item">Speakers</h5></button>
              <button mat-menu-item routerLink="/categories/vacuum&moprobots"><h5 class="nav-menu-item">Vacuum & Mop robots</h5></button>
              <button mat-menu-item routerLink="/categories/locks&doorbells"><h5 class="nav-menu-item">Locks & Door bells</h5></button>
              <button mat-menu-item routerLink="/categories/blinds&curtains"><h5 class="nav-menu-item">Blinds & Curtains</h5></button>
            </mat-menu>
            <mat-menu #sensorscontrollers="matMenu">
              <button class="nav-button" mat-menu-item routerLink="/categories/door&windowsensors"><h5 class="nav-menu-item">Door & Window sensors</h5></button>
              <button mat-menu-item routerLink="/categories/flood&waterleaksensors"><h5 class="nav-menu-item">Flood & Water leak sensors</h5></button>
              <button mat-menu-item routerLink="/categories/motion&vibrationsensors"><h5 class="nav-menu-item">Motion & vibration sensors</h5></button>
              <button mat-menu-item routerLink="/categories/switches&dimmers"><h5 class="nav-menu-item">Switches & Dimmers</h5></button>
              <button mat-menu-item routerLink="/categories/temperature&humiditysensors"><h5 class="nav-menu-item">Temperature & Humidity sensors</h5></button>
              <button mat-menu-item routerLink="/categories/thermostats"><h5 class="nav-menu-item">Thermostats</h5></button>
            </mat-menu>
            <mat-menu #software="matMenu">
              <button class="nav-button" mat-menu-item routerLink="/categories/automation"><h5 class="nav-menu-item">Automation</h5></button>
              <button mat-menu-item routerLink="/categories/virtualassistant"><h5 class="nav-menu-item">Virtual Assistant</h5></button>
            </mat-menu>
            <mat-menu #infrastructure="matMenu">
              <button class="nav-button" mat-menu-item routerLink="/categories/micro-chips&micro-controllers"><h5 class="nav-menu-item">Micro-chips & Micro-Controllers</h5></button>
              <button mat-menu-item routerLink="/categories/routers&switches"><h5 class="nav-menu-item">Routers & Switches</h5></button>
              <button mat-menu-item routerLink="/categories/hubs&gateways"><h5 class="nav-menu-item">Hubs & Gateways</h5></button>
            </mat-menu>
        </mat-menu>
    </div>
    <app-search class="app-search"></app-search>
    <!--
      <button 
        *ngIf="!isUserConnected" 
        routerLink="/login" 
        mat-button 
        class="login-button" 
       >
        Log-in<span class="material-icons" style="font-size: 28px; margin-left: 10px">
            account_circle
        </span>
      </button>
      <button 
        *ngIf="isUserConnected" 
        mat-button 
        class="login-button" 
        style="font-family: 'Montserrat', sans-serif; font-weight: 500;" 
        [matMenuTriggerFor]="menu"
      >
      <span class="material-icons" style="font-size: 28px; display: grid">account_circle<span class="hello-text" *ngIf="isUserConnected" >Hi, {{ userPseudo }}</span></span>
      </button>
      <mat-menu mat-stroked-button #menu="matMenu">
        <button class="nav-button" mat-menu-item routerLink="/profile/likes">My Likes</button>
        <button mat-menu-item routerLink="/" (click)="onSignOut()">Sign out</button>
    </mat-menu>
    -->
  </div>
</mat-toolbar>