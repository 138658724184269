<div *ngIf="showTable2" class="container-fluid" style="margin: 30px auto;">
  <button mat-raised-button color="primary" routerLink="/" >Home</button>
  <button mat-raised-button color="warn" [routerLink]="'/products/' + productId" style="margin-left: 10px;" >Product</button>
  <h5 style="text-align: center;">Edit {{ product.name}}</h5>
  <div class="row">
    <div  class="col-10 mx-auto">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
        <ng-container matColumnDef="feature">
          <th mat-header-cell *matHeaderCellDef> Spec </th>
          <td mat-cell *matCellDef="let product"> {{product.feature }} </td>
          <td mat-footer-cell *matFooterCellDef>
            <mat-form-field class="example-form-field">
                <input matInput type="text" [(ngModel)]="newFeature">
            </mat-form-field>
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let product">
            <select *ngIf="product.feature == 'category'" [(ngModel)]="product.value" [productCategory]="product.value">
              <option *ngFor="let cat of categoriesList | keyvalue" [value]="cat.key">{{ cat.key }}</option>
            </select>
            <select *ngIf="product.feature == 'subcategory'" [(ngModel)]="product.value" >
              <option *ngFor="let subcat of categoriesList[productCategory]" [value]="subcat">{{ subcat }}</option>
            </select>
            <textarea *ngIf="!['category', 'subcategory'].includes(product.feature)"[(ngModel)]="product.value" type="text" style="width: 70%;"></textarea>
            <button mat-mini-fab color="primary" style="margin-left: 40px; ;" (click)="onEditProduct(product.feature, product.value)">
              <span class="material-icons save-button" >
                  save
              </span>
          </button>
          <button mat-mini-fab color="primary" style="margin-left: 5px;"  (click)="onEditProduct(product.feature, product.value, true)">
              <span class="material-icons save-button">
                  clear
              </span>
          </button>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <mat-form-field class="example-form-field" style="width: 70%;">
                <input matInput type="text" [(ngModel)]="newValue">
            </mat-form-field>
            <button color="primary" mat-mini-fab style="margin-left: 40px;" (click)="onEditProduct(newFeature, newValue)">
                <span class="material-icons save-button">
                    add_circle
                </span>   
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

    </table>
    </div>
  </div>
</div>
