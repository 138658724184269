import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HAREPO';
  constructor(private router: Router) {
    var firebaseConfig = {
      apiKey: "AIzaSyAsiRongP949CgGgCyKmF1PuCvN22ArjB0",
      authDomain: "home-automation-repository.firebaseapp.com",
      databaseURL: "https://home-automation-repository.firebaseio.com",
      projectId: "home-automation-repository",
      storageBucket: "home-automation-repository.appspot.com",
      messagingSenderId: "676071506421",
      appId: "1:676071506421:web:2a2ffd130d3d669683e0bc",
      measurementId: "G-4V6EXF8Y46"
    };

    firebase.default.initializeApp(firebaseConfig);
    firebase.default.analytics();
    // firebase.default.storage();

  }
}
