<div class="row">
    <div class="col-sm-12">
        <table *ngIf="showTable" mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef="brand">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
              <td mat-cell *matCellDef="let product" class="text"> {{product.brand}} </td>
            </ng-container>
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Product </th>
              <td mat-cell *matCellDef="let product" class="text"> {{product.name}} </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let product" class="text"> {{product.description}} </td>
            </ng-container>
            <ng-container matColumnDef="network">
                <th mat-header-cell *matHeaderCellDef> Network </th>
                <td mat-cell *matCellDef="let product" class="text"> {{product.network}} </td>
            </ng-container>
            <ng-container matColumnDef="link">
              <th mat-header-cell *matHeaderCellDef> Link </th>
              <td mat-cell *matCellDef="let product" class="text"><button mat-raised-button [routerLink]="product.link" [matTooltip]="'https://homeautomation.tech' + product.link" class="text"><img src="assets/link.png"></button></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>