const CATEGORIES = {
    "Sensors & Controllers": [
        "Motion & vibration sensors",
        "Door & window sensors",
        "Plugs",
        "Temperature & humidity sensors",
        "Flood & Water leak sensors",
        "Switches & Dimmers"
    ],
    "Devices & equipements": [
        "Speakers",
        "Bulbs & Lights", 
        "Cleaning robots", 
        "Video Cameras", 
        "Locks & Doorbells"
    ],
    "Platforms": [
        "Ships", 
        "Servers", 
        "Cloud providers", 
        "Hub & Bridges", 
        "Routers & Switches"
    ],
    "Softwares": [
        "Automation", 
        "Assistant", 
        "Apps & Tools"
    ]
}

const SUBCATEGORIES = []

export class Product {
    id: string
    name: string
    brand: string
    description: string
    network: string
    amazonUrl: string
    officialUrl: string
    imageUrl: string
    category: string
    subcategory: string
    likes: number

    constructor(
        id?:string, 
        name?: string, 
        brand?: string, 
        description?: string, 
        network?: string, 
        amazonUrl?: string,
        officialUrl?: string, 
        imageUrl?: string, 
        category?: string, 
        subcategory?: string
        ) {
            this.id = id || '';
            this.name = name || '';
            this.brand = brand || '';
            this.description = description || ''; 
            this.network = network || ''; 
            this.amazonUrl = amazonUrl || ''; 
            this.officialUrl = officialUrl || '';
            this.imageUrl = imageUrl || ''; 
            this.category = category || '';
            this.subcategory = subcategory || ''; 
            this.likes = 0;
    }

    toJSONtoDB(): Object{
        return new Object({
            name: this.name, 
            brand: this.brand,
            description: this.description,
            amazonUrl: this.amazonUrl, 
            officialUrl: this.officialUrl, 
            category: this.category, 
            subcategory: this.subcategory,
            imageUrl: this.imageUrl,
            network: this.network,
            likes: this.likes
        }) 
    }

    parse(value: Object) {
        this.id = value['id'];
        this.name = value['name'];
        this.brand = value['brand']; 
        this.description = value['description'];
        this.category = value['category']; 
        this.subcategory = value['subcategory']; 
        this.amazonUrl = value['amazonUrl']; 
        this.officialUrl = value['officialUrl']; 
        this.imageUrl = value['imageUrl']; 
        this.network = value['network']; 
        this.likes = value['likes']
    }
    
}

export class Bulb extends Product {
    lumenOutput: number
    operationPower: number
    standbyPower: number
    colorTemperature: string
    capType: string[];

    constructor(lumentOutput: number, operationPower: number, stanbyPower: number, colorTemperature: string, capType: string[]) {
        super();
        this.lumenOutput = lumentOutput; 
        this.operationPower = operationPower; 
        this.standbyPower = stanbyPower; 
        this.colorTemperature = colorTemperature; 
        this.capType = capType; 
    }

    getLumenOuput() : string {
        return (this.lumenOutput.toString() + 'W');
    }
}