import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  isSidenavOpened: boolean = false; 
  isUserConnected: boolean = false; 
  userPseudo: string; 
  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.isUserConnected = this.userService.getLocalStorageConnected();
    if (this.isUserConnected) {
      this.userService.getPseudo()
      .then((pseudo) => {
        this.userPseudo = pseudo;
      })
      .catch((error) => {
        this.isUserConnected = false; 
        console.log(error);
      })
    }
  }
  
  openSidenav(): void {
    this.isSidenavOpened = ! this.isSidenavOpened;
  }
}
