import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductService } from 'src/app/product.service';
import { Comment } from './comment.model'

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input() comment: Comment;
  @Input() productId: string;
  @Input() user: any; 
  editModeAllowed: boolean = false; 
  @Output() commentModified: EventEmitter<any> = new EventEmitter();
  starsDisplay: Array<boolean>;
  constructor(
    private productService: ProductService
  ) { }

  ngOnInit(): void {
    this.starsDisplay = [false, false, false, false, false]
    for (let index of [0,1,2,3,4]) {
      this.starsDisplay[index] = index <= this.comment.rating - 1 ? true : false;
    }  

    if(this.user && this.user.uid) {
      if(this.user.uid == this.comment.authorId) {
        this.editModeAllowed = true; 
      }
    }
  }

  onRemoveComment() {
    this.productService.removeComment(this.productId, this.comment.authorId, this.comment.authorPseudo, this.comment.rating, this.comment.content)
      .then(() => {
        this.commentModified.emit();
      })
      .catch((error) => {
        console.error(error);
      })
  }

}
