<mat-accordion>
    <mat-expansion-panel hideToggle style="margin: 20px 0px; padding: 5px;" class="mat-custom-class">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <h6><span class="mdi mdi-filter" style="margin-right: 10px;"></span>CATEGORIES</h6>
        </mat-panel-title>
        </mat-expansion-panel-header>
        <form *ngIf="isLoaded" [formGroup]="filterCatForm">
            <div *ngFor="let cat of categoryList | keyvalue" class="form-group">
                <p style="font-weight: 500;">{{ cat.key }} </p>
                <input type="checkbox" class="checkbox" [formControlName]="cat.key" (click)="onChanges(cat.key, true)" style="margin-left: 10px">
                <label [attr.for]="cat.key">All</label>
                <div *ngFor="let subCat of cat.value" style=" margin-left: 20px;">
                    <input type="checkbox" class="checkbox" [formControlName]="subCat" (click)="onChanges(cat.key, false, subCat)">
                    <label [attr.for]="subCat">{{ subCat }}</label>
                </div>
            </div>
        </form>
    </mat-expansion-panel>
    <mat-expansion-panel hideToggle style="margin: 20px 0px; padding: 5px;" class="mat-custom-class">
        <mat-expansion-panel-header>
          <mat-panel-title>
              <h6><span class="mdi mdi-filter" style="margin-right: 10px;"></span>BRANDS</h6>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form *ngIf="isLoaded && brandList.length != 0" [formGroup]="filterBrandForm">
            <div class="brand">
                <input type="checkbox" class="checkbox" [checked]="brandDisplayAll" checked (click)="onChangeBrands(true)">
                <label for="allBrand">All</label>
            </div>
            <div *ngFor="let brand of brandList" class="brand">
                <input type="checkbox" class="checkbox" [formControlName]="brand" (click)="onChangeBrands()" style="margin-left: 20px">
                <label [attr.for]="brand" style="margin-bottom: 4px!important;">{{ brand }}</label>
            </div> 
          </form>
    </mat-expansion-panel>
</mat-accordion>