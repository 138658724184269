import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/product.service';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { UserService } from 'src/app/services/user.service';
import { DialogComponent } from 'src/app/body/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-product-show',
  templateUrl: './product-show.component.html',
  styleUrls: ['./product-show.component.css']
})
export class ProductShowComponent implements OnInit {
  @Input() productId: string;
  product: any = {};
  likedByUser: boolean = false;
  displayedColumns: string[] = ['feature', 'value'];
  dataSource: any[];
  showTable: boolean = false;
  allowEditMode: boolean = false;
  editModeEnabled: boolean = false;
  dataSourceForEditMode: any[];
  newFeature: string = '';
  newValue: string = '';
  userAlreadyCommented: boolean =false;
  isUserConnected: boolean = false;
  user: any;
  @ViewChild(DialogComponent) DialogComponent: DialogComponent


  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private userService: UserService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    // Get product information from database using product service
    this.dataSource = []
    this.dataSourceForEditMode = [];
    firebase.default.auth().onAuthStateChanged((user) => {
      if (user) {
        this.isUserConnected = true;
        this.user = user;
        this.allowEditMode = user.uid == 'Om6i6VAP7oOmDv2JG9EV40Q0K292' ? true : false;
        if (this.userService.getLocalStorageConnected()) {
          this.likedByUser = this.userService.getLocalStorageLikes()[this.productId];
        };
      } else {
        this.user = user;
        this.allowEditMode = false;
      }
    });

    this.productService.getProductNew(this.productId).then((product) => {
      this.product = product;

      var pathReference = firebase.default.storage().ref('images/' + product['imageUrl']);
        pathReference.getDownloadURL().then((url) => {
        this.product['image']= url;
      });

      for (let feature in this.product) {
        if (['network'].includes(feature))
        {
          this.dataSource.push({
            feature: feature,
            value: this.product[feature]
          });
        }

        if(this.product['subcategory'] == 'Bulbs & Lights') {
          if(['lumenOutput', 'operationPower', 'standbyPower'].includes(feature)) {
            this.dataSource.push({
              feature: feature,
              value: this.product[feature]
            });
          }
        }

        this.dataSourceForEditMode.push({
          feature: feature,
          value: this.product[feature]
        });
      };
      this.showTable = true;
    });

    this.userService.getComments()
    .then((comments) => {
      comments.forEach(comment => {
        if(comment.productId == this.productId) {
          this.userAlreadyCommented = true;
        }
      });
    })
    .catch((error) => {
      console.error(error);
    })

    if(!this.product.comments) {
      this.product.comments = [];
    }

  };

  onLike() {
    if (!this.userService.getLocalStorageConnected()) {
      this.openDialog();
      return false;
    }
    else {
      if (!this.likedByUser) {
        this.product['likes']++;
      }
      else {
        this.product['likes']--;

      }
      this.likedByUser = !this.likedByUser;
      this.productService.unlikeProduct(this.productId, this.product['likes']);
      this.userService.onLikeProduct(this.productId, this.likedByUser);
    }
  };

  openDialog() {
    this.dialog.open(DialogComponent);
  }

  onRefresh() {
    this.ngOnInit();
  }

}
