import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

@Injectable({
    providedIn: 'root'
  })
  export class UserService { 
    user: any;
    uid: string;
    pseudo: string; 
    isConnected: boolean; 

    constructor(){
    }

    initService() {
        return new Promise((resolve, reject) => {
            firebase.default.auth().onAuthStateChanged(user => {
                if(user){
                    this.uid = user.uid;
                    this.pseudo = user.displayName;
                    this.isConnected = true; 
                    resolve(true);
                }
                else {
                    this.isConnected = false; 
                    resolve(false);
                }
            }, error => {
                reject(error);
            })
        })
    }

    initUser(uid: string){
        let user = firebase.default.auth().currentUser; 
        if (user) {
            this.pseudo = user.displayName;
        }
        return new Promise(
            (resolve, reject) => {
                firebase.default.firestore().collection("users").doc(uid).get()
                    .then(
                        (snapshot) => {
                            this.user = snapshot.data();
                            this.uid = uid;
                            if (!this.user['likes']) {
                                this.user['likes'] = {'blank': 'blank'}
                            }
                            this.setLocalStorage();
                            resolve(snapshot.data());
                    },
                    (error) => {
                        reject(error);
                    }
                    )
            }
        )
    }

    getUserInfo() {
        if (this.getLocalStorageUser()) {
            return this.getLocalStorageUser(); 
        }
        else {
            this.initUser(firebase.default.auth().currentUser.uid).then(
                (data) => {
                    return this.user; 
                }
            )
        }
    }

    getUser() {
        return new Promise (
            (resolve, reject) => {
                if (this.user) {
                    resolve(this.user); 
                }
                else {
                    firebase.default.auth().onAuthStateChanged(
                        (user) => {
                            if (user) {
                                this.initUser(user.uid).then(
                                    (user) => {
                                        resolve(user);
                                    }
                                )
                            }
                        }
                    )
                }
            }
        )
    }

    setUserInfo(uid: string, field:string, value: string) {
        return new Promise (
            (resolve, reject) => {
                firebase.default.firestore().collection("users").doc(uid).update({field: value})
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            })
    }

    getUserUid(){

        return new Promise(
            (resolve, reject) => {
                if (this.getLocalStorageUid()) {
                    resolve(this.getLocalStorageUid());
                }
                firebase.default.auth().onAuthStateChanged(
                    (user) => {
                        if (user) {
                            resolve(user.uid);
                        }
                        else{
                            resolve('anonymous');
                        }
                    }
                )
            }
        )
    }
    
    onSignOut(){
        this.user = null; 
        this.clearLocalStorage();
    }

    onLikeProduct(productId: string, liked: boolean) {
        this.uid = firebase.default.auth().currentUser.uid; 
        this.updateLocalStorageLikes(productId, liked);
        let key = `likes.${productId}`;
        let newLike = {};
        newLike[key] = liked;
        return new Promise (
            (resolve, reject) => {
                firebase.default.firestore().collection("users").doc(this.uid).update(newLike)
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                })
            }
        )
    }

    setLocalStorage() {
        localStorage.setItem('uid', this.uid);
        localStorage.setItem('pseudo', this.pseudo);
        localStorage.setItem('user', JSON.stringify(this.user));
        localStorage.setItem('likes', JSON.stringify(this.user['likes']));
        localStorage.setItem('connected', 'true');
    }

    getLocalStorage() {
        return {
            'uid': localStorage.getItem('uid'),
            'user': localStorage.getItem('user'), 
            'connected': localStorage.getItem('connected'), 
            'likes': localStorage.getItem('likes'), 
            'pseudo': localStorage.getItem('pseudo')
        }
    }

    getLocalStorageUid(): string {
        return localStorage.getItem('uid');
    }

    getLocalStorageUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    setLocalStorageConnected() {
        localStorage.setItem('connected', 'true');
    }

    setLocalStorageLikes() {
        localStorage.setItem('likes', JSON.stringify(this.user['likes']));
    }

    updateLocalStorageLikes(productId: string, liked: boolean) {
        if (!this.user) {
            this.user = this.getLocalStorageUser();
        }
        this.user['likes'][productId] = liked;
        this.setLocalStorage();
    }

    getLocalStorageConnected(): boolean {
        if (localStorage.getItem('connected') == 'true') {
            return true; 
        }
        else {
            return false; 
        }
    }

    getLocalStorageLikes() {
        return JSON.parse(localStorage.getItem('likes'));
    }

    getLocalStoragePseudo() {
        return localStorage.getItem('pseudo');
    }

    clearLocalStorage() {
        localStorage.removeItem('uid');
        localStorage.removeItem('pseudo');
        localStorage.removeItem('user');
        localStorage.removeItem('connected');
        localStorage.removeItem('likes');
        localStorage.removeItem('pseudo');
    }

    getPseudo(): Promise<string> {
        return new Promise((resolve, reject) => {
            var pseudo = this.getLocalStoragePseudo(); 
            if (!pseudo || pseudo == 'undefined') {
                var user = firebase.default.auth().currentUser;
                if (user) {
                    resolve(user.displayName);
                }
                else {
                    reject("No user is signed-in")
                }
            }
            else {
                resolve(pseudo);
            }
        })
    }

    getComments(): Promise<any> {
        return new Promise ((resolve, reject) => {
            firebase.default.auth().onAuthStateChanged(user => {
                if (user) {
                    firebase.default.firestore().collection("users").doc(user.uid).get()
                        .then(snapshot => {
                            let comments = snapshot.data().comments
                            resolve(comments);
                        })
                        .catch((error) => {
                            reject(error);
                        })
                }
                else {
                    reject("No user signed-in")
                }
            })
        })
    }
}
