import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import * as firebase from 'firebase/app';
import 'firebase/database';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  password = new FormControl('');
  emailSignUp = new FormControl('', [Validators.required, Validators.email]);
  pseudo = new FormControl('', Validators.required);
  passwordSignUp = new FormControl('', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]);
  passwordSignUp2 = new FormControl('', [Validators.required, Validators.pattern(/[0-9a-zA-Z]{6,}/)]);
  signInForm: FormGroup;
  signUpForm: FormGroup;
  errorMessage: string;
  errorMessage2: string;
  hide = true;
  hide2 = true;
  hide3 = true;
  showSignInSpinner: boolean = false; 
  showSignUpSpinner: boolean = false; 
  reset = false;
  pseudoList: Array<string>;
  pseudoAvailable: string;
  isPseudoAvailable: boolean; 


  constructor(
    private formBuilder: FormBuilder, 
    private authService: AuthService, 
    private router: Router) {
   }

  ngOnInit(){
    firebase.default.firestore().collection("users").doc("public").get()
    .then((snapshot) => {
      this.pseudoList = snapshot.data()['allPseudos']
    })
    .catch((error) => {
      console.error(error);
    })
    this.initForm();
  }

  initForm() {
    this.signInForm = this.formBuilder.group({
      email: this.email,
      password: this.password
    });
    this.signUpForm = this.formBuilder.group({
      emailSignUp: this.emailSignUp,
      pseudo: this.pseudo,
      passwordSignUp: this.passwordSignUp,
      passwordSignUp2: this.passwordSignUp2
    });
    this.isPseudoAvailable = true; 
    this.signUpForm.get('pseudo').valueChanges.subscribe((value) => {
      if(this.pseudoList.includes(value)) {
        this.isPseudoAvailable = false; 
        this.pseudoAvailable = "This pseudo is already used. Plese choose another one."
      }
      else {
        this.isPseudoAvailable = true; 
        this.pseudoAvailable = "";
      }
    })
  }

  onSignIn() {
    this.showSignInSpinner = true; 
    const email = this.signInForm.get('email').value;
    const password = this.signInForm.get('password').value;
    this.authService.signInUser(email, password).then(
      () => {
        this.showSignInSpinner = false;
        this.router.navigate(['']);
      },
      (error) => {
        this.showSignInSpinner = false; 
        this.errorMessage = error; 
      }
    );
  }

  onSignUp() {
    this.showSignUpSpinner = true; 
    const email = this.signUpForm.get('emailSignUp').value;
    const password = this.signUpForm.get('passwordSignUp').value;
    const pseudo = this.signUpForm.get('pseudo').value; 
    this.authService.createNewUser(email, password, pseudo).then(
      (userId) => {
        this.showSignUpSpinner = false;
        this.router.navigate(['/']);

      },
      (error) => {
        this.errorMessage2 = error; 
        this.showSignUpSpinner = false; 
      }
    );
  }

  onResetPassword() {
    const email = this.signInForm.get('email').value;
    if (!email) {
      alert('Type in your email first'); 
    }
    else {
      this.authService.sendPasswordResetMail(email);
      this.reset = true; 
    }

  }

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a value';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  arePasswordsDifferent(): boolean {
    const pwd1 = this.signUpForm.get('passwordSignUp').value;
    const pwd2 = this.signUpForm.get('passwordSignUp2').value;
    return !(pwd1 == pwd2);
  }


}
