<mat-toolbar class="bg-dark">
    <button mat-icon-button  routerLink="/"><span class="material-icons" style="color: white">home</span></button>
    <a routerLink="/" ><h1 class="site-title">Home Automation</h1></a>
</mat-toolbar>
<div class="container-fluid">
    <div class="row">
        <div  *ngIf="errorCode" class="col-sm-8 mx-auto">
            <p style="margin-top: 100px;">{{ errorCode }}. Please try again.</p>
        </div>
        <div class="col-sm-8 mx-auto" *ngIf="codeChecked">
            <h1>Reset password form</h1>
            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPasswordForm()">
                <div class="example-container">
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your email</mat-label>
                      <input matInput placeholder="john@doe.com"  formControlName="email" style="width: 100%;" required>
                    </mat-form-field>
                  </div>
                  <div class="example-container">
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your new password</mat-label>
                      <input matInput [type]="hide ? 'password' : 'text'" formControlName="newPassword" required>
                      <span (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" class="material-icons showPasswordButton">{{hide ? 'visibility_off' : 'visibility'}}</span>
                    </mat-form-field>
                  </div>
                <button mat-raised-button type="submit"><span *ngIf="!showSpinner">Change password</span><mat-spinner *ngIf="showSpinner" diameter="25" style="margin: auto auto;"></mat-spinner></button>
            </form>
        </div>
    </div>
</div>