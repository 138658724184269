import { Component, Input, OnInit} from '@angular/core';
import { ProductService } from 'src/app/product.service';
import { UserService } from 'src/app/services/user.service';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
  selector: 'app-comment-div',
  templateUrl: 'comment-div.component.html',
  styleUrls: ['./comment-div.component.css'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({opacity: 0 }),
            animate('0.1s ease-out', 
                    style({opacity: 1}))
          ]
        ),
        transition(
          ':leave', 
          [
            style({opacity: 1 }),
            animate('0.1s ease-in', 
                    style({opacity: 0 }))
          ]
        )
      ]
    )
    ]
})
export class CommentdivComponent implements OnInit {
  @Input() userAlreadyCommented: boolean; 
  @Input() user: any; 
  @Input() productId: string; 
  @Input() product: any;
  isLoaded: boolean = false;

  comments: any; 
  constructor(
    private productService: ProductService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.userService.getComments()
    .then((comments) => {
      comments.forEach(comment => {
        if(comment.productId == this.productId) {
          this.userAlreadyCommented = true;
        }
      });
    })
    .catch((error) => {
      console.error(error)
    })

    this.productService.getComments(this.productId)
      .then((comments) => {
        this.comments = comments? comments: [];
        this.isLoaded = true; 
      })
      .catch((error) => {
        console.error(error); 
      })
  }

  onRefreshSubmitted() {
    this.ngOnInit();
    this.userAlreadyCommented = true;
  }

  onRefreshRemoved(){
    this.ngOnInit();
    this.userAlreadyCommented = false; 
  }
}
