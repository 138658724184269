<div style="position: relative">
    <h6 style="font-size: 16px; margin-bottom: 0px; margin-top: 7px; margin-left: 4px;">{{ comment.authorPseudo }}</h6>
    <div style="margin-left: 10px; display: flexbox;">
        <mat-icon *ngFor="let count of [1,2,3,4,5]" 
        class="star"
        [ngClass]="{'star-yellow': starsDisplay[count - 1]}"
    >grade</mat-icon>    
    <p style="font-size: 14px;">{{ comment.content }}</p>
    </div>
      <button *ngIf="editModeAllowed" mat-icon-button [matMenuTriggerFor]="menu" style="position: absolute; top: 5px; right: 2px; font-size: 12px; color: gray;">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onRemoveComment()">
          <mat-icon>remove</mat-icon>
          <span>Remove comment</span>
        </button>
      </mat-menu>
</div>
<mat-divider></mat-divider>