<app-header [isOnHomePage]="false"></app-header>
<div class="container-fluid">
    <div class="row my-auto vertical-center">
        <div class="col-sm-4 mx-auto" style="margin-top: 100px;">
            <h2>Login</h2>
            <form [formGroup]="signInForm">
                <div class="example-container">
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your email</mat-label>
                      <input matInput placeholder="john@doe.com" [formControl]="email" style="width: 100%;" aria-label="login-email-address" required>
                    </mat-form-field>
                  </div>
                  <div class="example-container">
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your password</mat-label>
                      <input matInput [type]="hide ? 'password' : 'text'" [formControl]="password" aria-label="login-password" required>
                      <span (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" class="material-icons showPasswordButton">{{hide ? 'visibility_off' : 'visibility'}}</span>
                    </mat-form-field>
                  </div>
                <div id="resetPassword">
                    <a id="resetPasswordLink" (click)="onResetPassword()">Forgot password ?</a>
                    <p *ngIf="reset">A message has been sent to your email address. Please follow the indications to reset your password. </p>
                </div>
                <button mat-raised-button type="submit" style="width: 100%;" [disabled]="signInForm.invalid" (click)="onSignIn()"><span *ngIf="!showSignInSpinner">SIGN-IN</span><mat-spinner *ngIf="showSignInSpinner" diameter="25" style="margin: auto auto;"></mat-spinner></button>
            </form>
            <p class="text-danger">{{ errorMessage }}</p>
        </div>
        <div class="col-sm-4 mx-auto" style="margin-top: 100px;">
            <h2>Register</h2>
            <form [formGroup]="signUpForm">
                <div class="example-container">
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your email</mat-label>
                      <input matInput placeholder="john@doe.com" [formControl]="emailSignUp" style="width: 100%;" required>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your pseudo</mat-label>
                      <input matInput placeholder="johndoe" [formControl]="pseudo" style="width: 100%;" aria-label="Pseudo" required>
                    </mat-form-field>
                    <h6 class="pseudo-message">{{ pseudoAvailable }}</h6>
                  </div>
                  <div class="example-container">
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Enter your password</mat-label>
                      <input matInput [type]="hide2 ? 'password' : 'text'" [formControl]="passwordSignUp" required>
                      <span (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2" class="material-icons showPasswordButton">{{hide2 ? 'visibility_off' : 'visibility'}}</span>
                    </mat-form-field>
                    <mat-form-field appearance="fill" style="width: 100%;">
                      <mat-label>Re-enter your password</mat-label>
                      <input matInput [type]="hide3 ? 'password' : 'text'" [formControl]="passwordSignUp2" required>
                      <span (click)="hide3 = !hide3" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide3" class="material-icons showPasswordButton">{{hide3 ? 'visibility_off' : 'visibility'}}</span>
                    </mat-form-field>
                  </div>
                <button mat-raised-button type="submit" style="width: 100%; margin-left: 2px;" [disabled]="signUpForm.invalid || arePasswordsDifferent()  || !isPseudoAvailable" (click)="onSignUp()"><span *ngIf="!showSignUpSpinner">SIGN-UP</span><mat-spinner *ngIf="showSignUpSpinner" diameter="25" style="margin: auto auto;"></mat-spinner></button>
            </form>
            <p class="text-danger">{{ errorMessage2 }}</p>
        </div>
    </div>
</div>