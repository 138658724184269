import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() openSidenavEvent = new EventEmitter();
  @Input() isOnHomePage = true; 
  isSidenavOpened: boolean = false;
  @Input() isUserConnected: boolean = false;
  @Input() userPseudo: string; 

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {

  }

  toCategoriesURI(string: string): string {
    return "/categories/" + string.toLowerCase().replace(/\s+/g, '') ;
  }

  onOpenSidenav(): void {
    this.isSidenavOpened = ! this.isSidenavOpened; 
    this.openSidenavEvent.next();
  }

  onSignOut(): void {
    this.authService.signOutUser()
    .then(() => {
      location.reload();
    })
    .catch((error) => {
      console.error(error);
    })
  }

}
