<div class="row">
    <div *ngFor="let product of productList | keyvalue;" class="col-6 col-sm-4 col-md-4 col-xl-2"  style="padding: 5px;"  >
        <app-product-details
        [productId]="product.key"
        [product]="product.value" 
        >
        </app-product-details>
    </div>
</div>

