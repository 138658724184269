
<div class="row card-custom">
    <a [href]="'/edit/'+ productId"><button *ngIf="allowEditMode" mat-mini-fab color="primary" class="settings-button" (click)="editModeEnabled = !editModeEnabled"><span  class="material-icons" style="font-size: 22px;">
        settings
        </span></button></a>
    <div class="card-text col-sm-8">
        <h6 class="card-brand">{{ product.brand }}</h6>
        <h5 class="card-product">{{ product.name }}</h5>
        <p>{{ product.description }}</p>
        <table *ngIf="showTable" mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="feature">
              <td mat-cell *matCellDef="let product" style="font-size: 12px;"> {{product.feature | titlecase }} </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <td mat-cell *matCellDef="let product" style="font-size: 12px; padding: 5px 10px;"> {{product.value}} </td>
            </ng-container>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="btn-div">
            <a mat-raised-button color="primary" [href]="product.officialUrl" [disabled]="!product.officialUrl" target="_blank" class="btn">Link to official website</a>
            <a mat-raised-button color="primary" [href]="product.amazonUrl" [disabled]="!product.amazonUrl" target="_blank" class="btn-amz">Link to Amazon</a>
        </div>
        <div class="vote-div">
            <p class="like-numbers">{{ product.likes }}</p>
            <span class="mdi mdi-cards-heart custom-heart" (click)="onLike()" [ngClass]="{'red-heart': likedByUser, 'grey-heart': !likedByUser}"></span>
            <p *ngIf="likedByUser" style="font-size: 14px; color:#C70039; margin-left: 10px;">You liked this product</p>
        </div>
    </div>
    <div class="col-sm-4 mx-auto" style="min-height: 40vh;">
        <img [src]="product.image" class="card-image">
    </div>
    <div class="col-sm-12 mx-auto">
        <mat-tab-group>
            <mat-tab label="Reviews">
                <app-comment-div [user]="user" [userAlreadyCommented]="userAlreadyCommented" [productId]="productId" [product]="product"></app-comment-div>
            </mat-tab>
            <mat-tab label="Ressources">
                <app-ressources [ressources]="product.ressources"></app-ressources>
            </mat-tab>
            <!-- <mat-tab label="Details"> Spec details </mat-tab> -->
        </mat-tab-group>
    </div>
</div>

