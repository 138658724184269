<mat-toolbar class="bg-dark">
    <button mat-icon-button  routerLink="/"><span class="material-icons" style="color: white">home</span></button>
    <a routerLink="/" ><h1 class="site-title">Home Automation</h1></a>
</mat-toolbar>
<br>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-8 mx-auto">
            <h2>My likes
            </h2>
            <table *ngIf="showTable" mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="brand">
                  <th mat-header-cell *matHeaderCellDef> Brand </th>
                  <td mat-cell *matCellDef="let product"> {{product.brand}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Product </th>
                  <td mat-cell *matCellDef="let product"> {{product.name}} </td>
                </ng-container>
                <ng-container matColumnDef="link">
                  <th mat-header-cell *matHeaderCellDef> Link </th>
                  <td mat-cell *matCellDef="let product"><button mat-raised-button [routerLink]="product.link" [matTooltip]="'https://homeautomation.tech' + product.link">See product</button></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
              
        </div>
    </div>
</div>