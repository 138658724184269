import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetComponent } from './auth/reset/reset.component';
import { CreateProductComponent } from './body/create-product/create-product.component';
import { ProductComponent } from './body/product-list/product/product.component';
import { MainComponent } from './main/main.component';
import { LikesComponent } from './profile/likes/likes.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardUnauthenticatedService } from './services/auth-guard-unauthenticated.service'
import { AuthGuardAdminService } from './services/auth-guard-admin.service';
import { EditProductComponent } from './body/product-list/product/edit-product/edit-product.component';
import { CategoryShowComponent } from './body/product-list/category-show/category-show.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  {path: '', component: MainComponent},
  {path: 'products/:id', component: ProductComponent},
  {path: 'login', component: LoginComponent},
  {path: 'reset', component: ResetComponent},
  {path: 'new', canActivate:[AuthGuardAdminService], component: CreateProductComponent},
  {path: 'edit/:id', canActivate:[AuthGuardAdminService], component: EditProductComponent},
  {path: 'categories/:id', component: CategoryShowComponent},
  {path: 'profile/likes', canActivate: [AuthGuardService], component: LikesComponent},
  {path: 'admin', component: AdminComponent},
  {path: '**', component:MainComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
