import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';


@Component({
  selector: 'app-product-show-dialog',
  template: `
  <app-product-show [productId]='productId'></app-product-show>`
  })
export class ProductShowDialogComponent implements OnInit {
  productId: string;
  product: any;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.productId = this.config['data']['productId'];
    this.product = this.config['data']['product'];
    }
  }
