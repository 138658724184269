import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/product.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  productId: string;
  
  constructor(
    private route: ActivatedRoute, 
    private productService: ProductService,
    private _location: Location
    ) { }

  ngOnInit(): void {
    // Get the id of the product from the url
    this.productId =  this.route.snapshot.params['id'];
  }

  back(){
    this._location.back();
  }

}
