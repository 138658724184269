<div class="card-custom-small" (click)="onClick($event)">
    <div class="card-text">
        <h6 *ngIf="product.brand != product.name" class="card-brand-small">{{ product.brand }}</h6>
        <h5 class="card-product-small">{{ product.name }}</h5>
    </div>
    <div  class="mx-auto">
        <img [src]="product.iconUrl" class="my-auto" class="card-image-small">
    </div>
    <div class="vote-div">
        <p class="like-numbers">{{ ratingMean }}</p><span *ngIf="ratingMean" class="mdi mdi-star star" style="margin: 5px;"></span>
        <p class="like-numbers">{{ product.likes }}</p>
        <span [matTooltip]="likedByUser ? 'You liked this product': 'Click to like this product'" id="heart" class="mdi mdi-cards-heart custom-heart" [ngClass]="{'red-heart': likedByUser, 'grey-heart': !likedByUser}"></span>
    </div>
</div>
